<template>
    <v-card style="position: relative;">
        <v-card-title
            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
            style="position: sticky; top: 0; width: 100%; z-index: 1000;"
        >
            <span class="light--text">{{`Editor de fórmulas ${accountGroupDescription}`}}</span>

            <v-spacer></v-spacer>

            <v-btn
                class="pa-0"
                @click="$emit('closeDialogFormula')"
                min-width="48px"
                text dark
            >
                <v-icon color="light">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row no-gutters class="mt-2">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex align-center mt-2">
                            <v-col class="pa-0" cols="11" >
                                <v-textarea
                                    auto-grow
                                    rows="1"
                                    v-model="formula"
                                    class="pa-0 ma-0 mt-4"
                                    color="primary"
                                    label="Fórmula"
                                >
                                </v-textarea>
                            </v-col>

                            <v-col cols="1" align="end">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon color="primary"
                                            @click="formula = ''"
                                            :disabled="!formula.length"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-backspace</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Limpar tudo</span>
                                </v-tooltip>
                            </v-col>
                        </div>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-sheet max-height="200px" v-if="showAccounts"
                        style="position: absolute; top: 105px; left: 75px; z-index: 1000;"
                        elevation="2"
                    >
                        <v-list>
                            <v-list-item-group v-for="account in accountGroups" :key="account"
                                color="primary" class="d-flex align-center"
                            >
                                <v-list-item class="cursor-pointer" @click="attachAccount(account)">
                                    <v-list-item-title>{{ account }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-sheet>
                </v-expand-transition>

                <v-row no-gutters class="mt-4">
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                        <div class="d-flex flex-column">
                            <span class="text-subtitle-1 dark--text mr-1">Contas gerenciais</span>
                        </div>

                        <template v-if="!loading">
                            <template v-if="accountGroups.length">
                                <v-chip v-for="account in accountGroups" :key="account"
                                    color="primary"
                                    class="mt-2 mr-2"
                                    outlined
                                    style="cursor: pointer;"
                                    @click="formula += ` ${account}`"
                                >
                                    {{ account }}
                                </v-chip>
                            </template>

                            <div v-else class="mt-2">
                                <span>Nenhuma conta gerencial encontrada</span>
                            </div>
                        </template>

                        <div v-else class="d-flex justify-center align-start"
                            style="width: 100%; height: 100%;"
                        >
                            <v-btn text class="mt-8" color="primary"
                                :loading="true"
                            ></v-btn>
                        </div>
                    </v-col>

                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                        <div class="text-subtitle-1 dark--text">
                            Operações
                        </div>

                        <v-btn v-for="operation in mathOperations" :key="operation.name"
                            color="primary"
                            class="mt-2 mr-2"
                            outlined
                            style="cursor: pointer;"
                            @click="attachOperation(operation)"
                        >
                            {{ operation.sign }}
                        </v-btn>

                        <div class="text-subtitle-1 dark--text mt-4">
                            Valores de referência
                        </div>

                        <v-btn v-for="ref in refValues" :key="ref.name"
                            color="primary"
                            class="mt-2 mr-2"
                            outlined
                            style="cursor: pointer;"
                            @click="attachRef(ref)"
                        >
                            {{ ref.description }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
            <v-divider class="mx-4"></v-divider>
            <v-card-actions class="px-6 py-6">
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="mr-4"
                    @click="$emit('closeDialogFormula')"
                >
                    Cancelar
                </v-btn>
                <v-btn color="primary"
                    @click="setEditedRowFormula()"
                    :disabled="disabled"
                >
                    Confirmar
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'ZnapFormulaDialog',

    props: {
        editedRowFormula: { type: Object, required: true },
        filterConditions: { type: Array, required: true },
    },

    data() {
        return {
            formula: '',
            showAccounts: false,

            variables: [],
            selectedAccountGroups: [],
            accountGroups: [],

            mathOperations: [
                { name: 'equals', sign: '=' },
                { name: 'plus', sign: '+',  },
                { name: 'minus', sign: '-' },
                { name: 'divide', sign: '/' },
                { name: 'times', sign: '*' },
                { name: 'percentage', sign: '%' },
                { name: 'open parentheses', sign: '(' },
                { name: 'close parentheses', sign: ')' },
                { name: 'open brackets', sign: '[' },
                { name: 'close brackets', sign: ']' },
                { name: 'open braces', sign: '{' },
                { name: 'close braces', sign: '}' },
                { name: 'less than or equal to', sign: '<=' },
                { name: 'less than', sign: '<' },
                { name: 'greater than', sign: '>' },
                { name: 'greater than or equal to', sign: '>=' },
                { name: 'if', sign: 'IF(' },
                { name: 'if error', sign: 'IFERROR(' },
                { name: 'comma', sign: ',' },
            ],

            refValues: [
                { name: 'previous', description: 'Anterior', sign: '@P-1' },
                { name: 'next', description: 'Próximo', sign: '@P+1' }
            ],

            disabled: false,
            loading: false,
        }
    },

    computed: {
        accountGroupDescription() {
            return this.editedRowFormula.account_group_description ? `- ${this.editedRowFormula.account_group_description}` : ''
        }
    },

    watch: {
        formula(val) {
            if (val.slice(-1) === ':') {
                this.showAccounts = true
            } else {
                this.showAccounts = false
            }
        },
    },

    async created() {
        this.loading = true

        this.formula = this.editedRowFormula.formula || ''
        this.accountGroups = []
        
        let found = this.filterConditions.find(f => f.column === 'id_account_group_version')

        let payload = {
            filter: {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_account_group_version",
                        operator: "=",
                        value: found.value
                    }
                ]
            }
        }

        try {
            const res = await this.$http.post('https://api.account.znaptech.com/account-group/list', { ...payload })
            if (res) {
                res.data.rows.map(r => {
                    if (r.system_id) {
                        this.accountGroups.push(r.system_id)
                    }
                })

                this.loading = false
            }
        } catch (err) {
            this.$fnError(err)
            this.loading = false
        }
    },

    methods: {
        attachOperation(operation) {
            this.formula += ` ${operation.sign}`
        },

        attachAccount(account) {
            if (!this.selectedAccountGroups.includes(account)) {
                this.selectedAccountGroups.push(account)
            }

            let formula = this.formula
            if (formula.slice(-1) === ':') {
                formula = formula.slice(0, -1)
            }

            this.formula = `${formula}${account}`
            this.showAccounts = false
        },

        setEditedRowFormula() {
            let formula = this.formula

            let array = formula.split(' ')
            let variables = array.filter(a => a.includes(':'))

            this.selectedAccountGroups = []
            variables.forEach(v => {
                if (!this.selectedAccountGroups.includes(v)) {
                    this.selectedAccountGroups.push(v)
                }
            })

            let variable = this.selectedAccountGroups.join(';')

            this.$emit('setEditedRowFormula', { formula, variable })
        }
    },
}
</script>

<style scoped>
</style>
