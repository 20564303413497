<template>
    <v-container>
        <v-row no-gutters class="mt-2">
            <template>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div class="text-subtitle-1 dark--text">
                        Estilos:
                    </div>

                    <!-- :class="style" -->
                    <template v-if="rowStyles.length">
                        <v-chip v-for="style in rowStyles" :key="style"
                            close
                            @click:close="removeStyle(style)"
                            color="transparent"
                            class="mt-2"
                        >
                            {{ style }}
                        </v-chip>
                    </template>

                    <div v-else class="mt-2">
                        Nenhum estilo selecionado
                    </div>

                </v-col>
            </template>
        </v-row>

        <v-row no-gutters class="mt-4">
            <v-col>
                <span class="text-subtitle-1 dark--text">Preenchimentos:</span>
            </v-col>

            <v-col>
                <span class="text-subtitle-1 dark--text">Fontes:</span>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col class="d-flex">
                <div v-for="background in backgrounds" :key="background.class" class="d-flex flex-column">
                    <v-chip v-for="n in 4" :key="n"
                        label
                        class="mt-2 mr-2"
                        style="width: 24px; height: 24px; cursor: pointer;"
                        :class="`${background.class}-${n}`"
                        :style="rowStyles.includes(`${background.class}-${n}`) ? 'box-shadow: 0px 0px 0px 1.4px #666;' : ''"
                        @click="setBackground(`${background.class}-${n}`)"
                    >
                    </v-chip>
                </div>
            </v-col>

            <v-col class="d-flex flex-column">
                <div class="d-flex flex-column mt-2">
                    <span>Formatação: </span>

                    <div class="d-flex mt-2">
                        <v-btn text class="pa-0 mr-2"
                            v-for="fontFormat in fontFormats" :key="fontFormat.class"
                            @click="setFontFormat(fontFormat.class)"
                            :class="{'primary': rowStyles.includes(fontFormat.class)}"
                        >
                            <v-icon>{{ fontFormat.icon }}</v-icon>
                        </v-btn>
                    </div>
                </div>

                <div class="d-flex flex-column mt-4">
                    <span class="mb-2">Tamanho: </span>

                    <div class="d-flex">
                        <v-btn text class="pa-0 text-lowercase d-flex"
                            v-for="fontSize in fontSizes" :key="fontSize.class"
                            @click="setFontSize(fontSize.class)"
                            :class="{'primary': rowStyles.includes(fontSize.class)}"
                        >
                            <span>{{ fontSize.name }}</span>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-4">
            <v-col>
                <div>
                    Visualização:
                </div>
                <div style="width: 100%; height: 100%; border: 1px solid #999999; color: #222222;"
                    class="d-flex align-center justify-center rounded-lg"
                    :class="rowStyles.join(' ')"
                >
                    Lorem ipsum dolor sit amet
                </div>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-16">
            <v-btn
                color="primary"
                text
                @click="rowStyles = []"
                :disabled="rowStyles.length ? false : true"
            >
                Remover estilos
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="$emit('closeDialogStyles')"
                class="mr-4"
            >
                Cancelar
            </v-btn>
            <v-btn
                color="primary"
                @click="$emit('setEditedRowStyles', rowStyles.join(' '))"
                :disabled="disabled"
            >
                Salvar
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ZnapDialog',

    props: {
        editedRowStyles: { type: String }
    },

    data() {
        return {
            rowStyles: [],
            backgrounds: [
                { class: 'bordeaux', name: 'vinho' },
                { class: 'red', name: 'vermelho' },
                { class: 'gold', name: 'ouro' },
                { class: 'yellow', name: 'amarelo' },
                { class: 'lime', name: 'verde limão' },
                { class: 'green', name: 'verde' },
                { class: 'blue', name: 'azul' },
                { class: 'royal', name: 'azul royal' },
                { class: 'navy', name: 'azul marinho' },
                { class: 'purple', name: 'roxo' },
                { class: 'silver', name: 'prata' },
            ],
            fontSizes: [
                { class: 'font-smaller', name: '10pt' },
                { class: 'font-small', name: '12pt' },
                { class: 'font-normal', name: '14pt' },
                { class: 'font-big', name: '16pt' },
                { class: 'font-bigger', name: '18pt' },
            ],
            fontFormats: [
                { class: 'font-bold', name: 'Negrito', icon: 'mdi-format-bold' },
                { class: 'font-italic', name: 'Itálico', icon: 'mdi-format-italic' },
                { class: 'font-underline', name: 'Sublinhado', icon: 'mdi-format-underline' },
            ],
            disabled: true,
        }
    },

    watch: {
        rowStyles(val) {
            if (val.join(' ') === this.editedRowStyles) {
                this.disabled = true
            } else {
                this.disabled = false
            }
        }
    },

    created() {
        if (this.editedRowStyles === "" || this.editedRowStyles === null) {
            this.rowStyles = []
        } else {
            this.rowStyles = this.editedRowStyles.split(' ')
        }
    },

    methods: {
        removeStyle(style) {
            let index = this.rowStyles.findIndex(s => s === style)
            this.rowStyles.splice(index, 1)
        },

        setBackground(background) {
            let isBg = null
            this.rowStyles.forEach(style => {
                let styleClass = style.split('-')[0]
                let bgIndex = this.backgrounds.find(bg => bg.class === styleClass)
                if (bgIndex) isBg = bgIndex
            })

            let found = this.rowStyles.find(style => style === background)

            if (isBg) {
                if (found) {
                    this.removeStyle(background)
                } else {
                    this.rowStyles.splice(0, 1, background)
                }
            } else {
                this.rowStyles.splice(0, 0, background)
            }
        },

        setFontSize(fontSize) {
            let isFontSize = null
            this.rowStyles.forEach((style, index) => {
                let fsIndex = this.fontSizes.find(fs => fs.class === style)
                if (fsIndex) isFontSize = index
            })

            let found = this.rowStyles.find(style => style === fontSize)

            if (isFontSize !== null) {
                if (found) {
                    this.removeStyle(fontSize)
                } else {
                    this.rowStyles.splice(isFontSize, 1, fontSize)
                }
            } else {
                this.rowStyles.push(fontSize)
            }
        },

        setFontFormat(fontFormat) {
            let found = this.rowStyles.find(style => style === fontFormat)

            if (found) {
               this.removeStyle(fontFormat)
            } else {
               this.rowStyles.push(fontFormat)
            }
        }
    },
}
</script>

<style scoped>
/* --- CSS ROW STYLES --- */

/* FONTS */
.font-smaller {
  font-size: 10px !important;
}

.font-small {
  font-size: 12px !important;
}

.font-normal {
  font-size: 14px !important;
}

.font-big {
  font-size: 16px !important;
}

.font-bigger {
  font-size: 18px !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-light {
  font-weight: lighter !important;
}

.font-italic {
  font-style: italic;
}

.font-underline {
    text-decoration: underline;
}

/* COLORS */
.bordeaux-1 {
  background-color: #EFBFBF !important;
}

.bordeaux-2 {
  background-color: #DF7F7F !important;
}

.bordeaux-3 {
  background-color: #D04040 !important;
}

.bordeaux-4 {
  background-color: #C00000 !important;
}

.red-1 {
  background-color: #FFBFBF !important;
}

.red-2 {
  background-color: #FF7F7F !important;
}

.red-3 {
  background-color: #FF4040 !important;
}

.red-4 {
  background-color: #FF0000 !important;
}

.gold-1 {
  background-color: #FFEFBF !important;
}

.gold-2 {
  background-color: #FFDF7F !important;
}

.gold-3 {
  background-color: #FFD040 !important;
}

.gold-4 {
  background-color: #FFC000 !important;
}

.yellow-1 {
  background-color: #FFFFBF !important;
}

.yellow-2 {
  background-color: #FFFF7F !important;
}

.yellow-3 {
  background-color: #FFFF40 !important;
}

.yellow-4 {
  background-color: #FFFF00 !important;
}

.lime-1 {
  background-color: #E4F3D3 !important;
}

.lime-2 {
  background-color: #C8E7A7 !important;
}

.lime-3 {
  background-color: #ADDC7C !important;
}

.lime-4 {
  background-color: #92D050 !important;
}

.green-1 {
  background-color: #BFEBD3 !important;
}

.green-2 {
  background-color: #7FD7A7 !important;
}

.green-3 {
  background-color: #40C47C !important;
}

.green-4 {
  background-color: #00B050 !important;
}

.blue-1 {
  background-color: #BFEBFB !important;
}

.blue-2 {
  background-color: #7FD7F7 !important;
}

.blue-3 {
  background-color: #40C4F4 !important;
}

.blue-4 {
  background-color: #00B0F0 !important;
}

.royal-1 {
  background-color: #BFDBEF !important;
}

.royal-2 {
  background-color: #7FB7DF !important;
}

.royal-3 {
  background-color: #4094D0 !important;
}

.royal-4 {
  background-color: #0070C0 !important;
}

.navy-1 {
  background-color: #BFC7D7 !important;
}

.navy-2 {
  background-color: #7F8FAF !important;
}

.navy-3 {
  background-color: #405888 !important;
}

.navy-4 {
  background-color: #002060 !important;
}

.purple-1 {
  background-color: #DBCBE7 !important;
}

.purple-2 {
  background-color: #B797CF !important;
}

.purple-3 {
  background-color: #9464B8 !important;
}

.purple-4 {
  background-color: #7030A0 !important;
}

.silver-1 {
  background-color: #E8E8E8 !important;
}

.silver-2 {
  background-color: #D2D2D2 !important;
}

.silver-3 {
  background-color: #BBBBBB !important;
}

.silver-4 {
  background-color: #A5A5A5 !important;
}
</style>
